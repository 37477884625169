import React from "react";
import CompImage from "../common/comp-image";
import CompVideo from "../common/comp-video";
import PortableText from "../portableText";

const AboutBody = (props) => {
  return (
    <div className="min-vh-100">
      <div className="mw8 serif center pa2">
        <CompVideo urlVideo={props.urlVideo} />
        <div className="serif f4 lh-copy tj">
          <PortableText blocks={props.body} />
        </div>
        <CompImage image={props.mainImage} />
      </div>
    </div>
  );
};

export default AboutBody;
