import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/layout";
import PageTitle from "../components/common/page-title";
import AboutBody from "../components/about/about-body";

export const query = graphql`
  query AboutPageQuery {
    about: sanityAbout {
      title
      urlVideo
      _rawBody
      mainImage {
        asset {
          id
        }
        caption
        alt
      }
    }
  }
`;

const AboutPage = (props) => {
  const { data } = props;

  const about = (data || {}).about;

  return (
    <Layout>
      <PageTitle title={about.title} />
      <AboutBody
        urlVideo={about.urlVideo}
        mainImage={about.mainImage}
        body={about._rawBody}
      />
    </Layout>
  );
};

export default AboutPage;
