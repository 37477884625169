import React from "react";

const CompVideo = (props) => {
  return (
    <section>
      {props.urlVideo && (
        <div className="aspect-ratio aspect-ratio--16x9 mv5">
          <iframe
            className="aspect-ratio--object"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            src={props.urlVideo}
          />
        </div>
      )}
    </section>
  );
};

export default CompVideo;
